/* eslint-disable import/no-anonymous-default-export */
export default    [
    'Amber',
    'Tripel',
    'Bock',
    'Blond',
    'Dubbel',
    'IPA',
    'Porter',
    'Stout',
    'Weizen',
    'Witbier',
    'Gerstewijn',
    'Overig'
]
